



















































































import {
  toRefs,
  computed,
  defineComponent,
} from 'vue-demi'

import { RawLocation } from 'vue-router/types'

import { Routes } from '@/router'

interface Props extends Record<string, any> {
  readonly worker: Record<string, any>
}

export default defineComponent<Props>({
  components: {},

  props: {
    worker: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { worker } = toRefs(props)

    const route = computed<RawLocation>(() => ({ name: Routes.of.Workers.OVERVIEW, params: { id: worker.value.id } }))

    return { route }
  },
})

