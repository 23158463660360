




































































import { toRefs, computed, defineComponent } from 'vue-demi'

interface Category {
  id?: number
  key: string
  title: string
}

interface Props extends Record<string, any> {
  readonly show: boolean
  readonly selected: string[]
  readonly categories: Category[]
}

export default defineComponent<Props>({
  components: {},

  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },

    selected: {
      type: Array,
      required: true,
    },

    categories: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { selected } = toRefs(props)

    const model = computed({
      get: () => selected.value,
      set: v => { emit('update:selected', v) },
    })

    return { model }
  },
})

