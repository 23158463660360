import {
  Ref,
  onMounted,
} from 'vue-demi'

import { Company, User, Work } from '@/types/api'

import { usersRepository } from '@/repositories/users'
import { worksRepository } from '@/repositories/works'
import { companiesRepository } from '@/repositories/companies'

export function useLoadUserHook(userId: number, user: Ref<User | undefined>) {
  if (userId == null || typeof user === 'undefined') { return }

  async function loadContactor() {
    user.value = await usersRepository.find({ id: userId })
  }

  onMounted(loadContactor)
}

export function useLoadWorkHook(workId: number, work: Ref<Work | undefined>) {
  if (workId == null || typeof workId === 'undefined') { return }

  async function loadWork() {
    work.value = await worksRepository.find({ id: Number(workId) })
  }

  onMounted(loadWork)
}

export function useLoadCompanyHook(companyId: number, company: Ref<Company | undefined>) {
  if (companyId == null || typeof companyId === 'undefined') { return }

  async function loadCompany() {
    company.value = await companiesRepository.find({ id: Number(companyId) })
  }

  onMounted(loadCompany)
}
